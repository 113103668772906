<script>
import FaqItem from "./FaqItem.vue";

export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          memo: '',
          items: []
        }
      }
    }
  },
  components: {
    FaqItem
  },
};
</script>

<template>
  <div class="mt-3">

    <div class="d-flex align-items-center mb-2">
      <div class="flex-shrink-0 me-1">
        <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
      </div>
      <div class="flex-grow-1">
        <h5 class="fs-16 mb-0 fw-semibold">{{ data.title }}</h5>
      </div>
    </div>

    <div class="accordion accordion-border-box" v-bind:id="'faqRoot_' + data.id" v-for="p in data.items" v-bind:key="p">
      <FaqItem v-bind:data="p"></FaqItem>
    </div>

  </div>
</template>