<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          memo: ''
        }
      }
    }
  },
  components: {
  },
};
</script>

<template>
  <div class="accordion-item mb-2">
    <h2 class="accordion-header" v-bind:id="'faqItem_' + data.id">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#faqItemTarget_' + data.id" aria-expanded="false" :aria-controls="'faqItemTarget_' + data.id">
        {{ data.title }}
      </button>
    </h2>
    <div v-bind:id="'faqItemTarget_' + data.id" class="accordion-collapse collapse" :aria-labelledby="'faqItem_' + data.id" data-bs-parent="#genques-accordion">
      <div class="accordion-body">
        {{ data.memo }}
      </div>
    </div>
  </div>
</template>
